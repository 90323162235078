import { useRecoilValue } from 'recoil';
import clsx from 'clsx'
import { FC, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Arrow90degRight, Pencil, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Typography } from '../../atoms'
import classes from './DeckTitleDisplay.module.css'
import { userInfoAtom } from '../../hooks/RecoilAtoms';
import UpgradePopupModal from '../../atoms/upgradePopup/UpgradePopupModal';

interface DeckTitleDisplayProps {
  deckIdx: number
  name: string
  count: number
  color: string
  editMode?: boolean
  onEditDeckClicked: (deckIdx: number) => void
  onDeleteDeckClicked: (deckIdx: number) => void,
  onOpenModal?: () => void,
  onMoveFlashcardsState?: () => void
}

const DeckTitleDisplay: FC<DeckTitleDisplayProps> = ({
  deckIdx,
  name,
  count,
  color,
  editMode = true,
  onEditDeckClicked,
  onDeleteDeckClicked,
  onOpenModal,
  onMoveFlashcardsState,
}) => {
  const { t } = useTranslation()
  const userInfo = useRecoilValue(userInfoAtom);
  const [upgradeModal, setUpgradeModal] = useState(false);
  return (
    <>
    <div className="d-flex align-items-center">
      <div
        className={clsx(classes.circle, 'mr-1')}
        style={{
          backgroundColor: color,
          width: 20,
          height: 20,
          marginRight: 10,
          padding: 5,
          border: '0.5px solid rgb(33, 37, 41)',
        }}
      >
      </div>
      <Typography className={classes.deckName} variant="h5">
        {name}
        <Typography variant='span' fontWeight='normal'>{ ` (${count})` }</Typography>
      </Typography>
      {editMode && (
        <Dropdown>
          <Dropdown.Toggle
            variant="default"
            className={classes.deckMenuDropdown}
          />
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey="1"
              onClick={onOpenModal}
            >
              <div className="d-flex align-items-center">
                <Arrow90degRight className={classes.icon} />
                Mover Baralho
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              onClick={onMoveFlashcardsState}
            >
              <div className="d-flex align-items-center">
                <Arrow90degRight className={classes.icon} />
                Mover Flashcards
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              onClick={() => onEditDeckClicked(deckIdx)}
            >
              <div className="d-flex align-items-center">
                <Pencil className={classes.icon} />
                {t('common.edit')}
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => onDeleteDeckClicked(deckIdx) }
            >
              <div className="d-flex align-items-center">
                <Trash className={classes.icon} />
                {t('common.delete')}
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
    <UpgradePopupModal showFreeExamModal={upgradeModal} setShowFreeExamModal={() => setUpgradeModal(!upgradeModal)} />
    </>
  )
}

export default DeckTitleDisplay
