import React from 'react';
import { useRecoilValue } from 'recoil';
import {Modal, Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '../../api/user/user';

type UpgradePopupModalProps = {
    showFreeExamModal?: boolean;
    setShowFreeExamModal?: () => void;
    isTest?: boolean;
}


export default function UpgradePopupModal({showFreeExamModal, setShowFreeExamModal, isTest = false}: UpgradePopupModalProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {data: userInfoData} = useUserInfo();

  return (
    <div>
      <Modal
        style={{marginTop: '20%'}}
        show={userInfoData?.is_premium || isTest ? false : true}
        keyboard={false}
       >
                <Modal.Header>
                  <Modal.Title>{t('nonPremium.upgrade')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('nonPremium.reasonsToUpgrade')}</Modal.Body>
                <Modal.Footer>
                  {isTest && (
                    <Button
                        onClick={setShowFreeExamModal}
                        variant="dark"
                    >
                       {t('navigation.createFreeExam')}
                    </Button>
                  )}

                  <Button onClick={() => navigate('/plans')} variant="success">
                    {t('common.upgrade')}
                  </Button>
                </Modal.Footer>
              </Modal>
    </div>
  );
}
