import { FC, Suspense } from 'react'
import { useCookies } from 'react-cookie'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import { NotificationsProvider } from './context/NotificationContext'
import { FullscreenLoader, Notifications, Routes } from './molecules'
import { RecoilRoot } from 'recoil';
import ThemeProvider from './templates/ThemeProvider/ThemeProvider'
import useQueryClient from './utils/queryClient'

const App: FC = () => {
  const [{ token }] = useCookies(['token'])
  const queryClient = useQueryClient()
  return (
    <Suspense fallback={<FullscreenLoader phrasesActive={false} />}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ThemeProvider>
              <NotificationsProvider>
                <Routes isLoggedIn={Boolean(token)} />
                <Notifications />
              </NotificationsProvider>
            </ThemeProvider>
          </Router>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
        </RecoilRoot>
    </Suspense>
  )
}

export default App
