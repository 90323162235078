// https://blog.logrocket.com/using-react-arborist-create-tree-components/

import clsx from 'clsx';
import { t } from 'i18next';
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Tree } from "react-arborist";
import { Button, Col, Row } from 'react-bootstrap';
import { ArrowBarLeft, ArrowBarRight, CaretDownFill, CaretRightFill, Check, Pencil, Plus, Tag as TagBootstrap, Trash, X } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Notepad, NotepadTag, Topic } from '../../api/model';
import {
  useMoveTopic,
  useTagCreate,
  useTopicCreate,
  useTopicUpdate
} from '../../api/notepad/notepad';
import { useDeleteTag, useDeleteTopic } from '../../api/topic/topic';
import BulletPoint from '../../assets/icons/BulletPoint';
import { Loading, Typography } from '../../atoms';
import RichTextEditor from '../../atoms/RichTextEditor/RichTextEditor';
import SearchBar from '../../atoms/SearchBar/SearchBar';
import TagsInput from '../../atoms/TagsInput/TagsInput';
import convertObjectArrayIntoStringArray from '../../utils/convertObjectArrayIntoStringArray';
import extractContentFromHTML from '../../utils/extractContentFromHTML';
import './NotepadBoard.css';
import classes from './NotepadBoard.module.css';

interface TopicTreeData {
  id: string
  notepad: string
  index: number
  name: string
  content: string
  parent?: string
  tags: NotepadTag[]
  updated_at: string
  children: TopicTreeData[]
}

/**
 * This function converts the topics data into a tree data structure
 * that can be used by the Tree component
 * Copies the subtopics into the children property
 *
 * @param topics - An array of topics
 * @returns The tree data structure
 */
const fromTopicsDataToTopicTreeDataConverter = (topics: Topic[]): TopicTreeData[] => {
  return topics.map((topic) => {
    const children = topic.subtopics
      ? fromTopicsDataToTopicTreeDataConverter(topic.subtopics)
      : []

    return {
      id: topic.id!,
      notepad: topic.notepad,
      index: topic.index,
      name: topic.title!,
      content: topic.content!,
      parent: topic.parent,
      tags: topic.tags,
      updated_at: topic.updated_at!,
      children: children,
    }
  })
}

/**
 * This function converts a single topic data into a tree data structure
 * that can be used by the Tree component
 * Copies the subtopics into the children property
 *
 * @param topic - The topic data
 * @returns The tree data structure
 */
const fromTopicToTopicTreeDataConverter = (topic: Topic): TopicTreeData => {
  const children = topic.subtopics
    ? fromTopicsDataToTopicTreeDataConverter(topic.subtopics)
    : []

  return {
    id: topic.id!,
    notepad: topic.notepad,
    index: topic.index,
    name: topic.title!,
    content: topic.content!,
    parent: topic.parent,
    tags: topic.tags,
    updated_at: topic.updated_at!,
    children: children,
  }
}

/**
 * This function converts the tree data structure into a topics data structure.
 * It copies the children into the subtopics property
 *
 * @param topics - The tree data structure
 * @returns An array of topics
 */
const fromTopicTreeDataToTopicsDataConverter = (topics: TopicTreeData[]): Topic[] => {
  return topics.map((topic) => {
    const subtopics = topic.children
      ? fromTopicTreeDataToTopicsDataConverter(topic.children)
      : []

    return {
      id: topic.id,
      notepad: topic.notepad,
      index: topic.index,
      title: topic.name,
      content: topic.content,
      parent: topic.parent,
      tags: topic.tags,
      updated_at: topic.updated_at,
      subtopics: subtopics,
    }
  })
}

/**
 * This function converts a single tree data into a topic data structure
 *
 * @param topic - The tree data
 * @returns The topic data
 */
const fromTopicTreeDataToTopicConverter = (topic: TopicTreeData): Topic => {
  const subtopics = topic.children
    ? fromTopicTreeDataToTopicsDataConverter(topic.children)
    : []

  return {
    id: topic.id,
    notepad: topic.notepad,
    index: topic.index,
    title: topic.name,
    content: topic.content,
    parent: topic.parent,
    tags: topic.tags,
    updated_at: topic.updated_at,
    subtopics: subtopics,
  }
}

interface TopicsContextProps {
  searchQuery: string
  setData: (data: TopicTreeData[]) => void
  moveTopic: (topic: Topic, newParent: string | null, newIndex: number) => void
  editTopic: (topic: Topic) => void
  deleteTopic: (topic: Topic) => void
  focusTopic: (topicId: string) => void
}

const TopicsContext = createContext<TopicsContextProps>({
  searchQuery: '',
  setData: () => { },
  moveTopic: () => { },
  editTopic: () => { },
  deleteTopic: () => { },
  focusTopic: () => { },
})

const TopicNode: FC = ({ node, style, dragHandle, tree }: any) => {
  const {
    editTopic,
    deleteTopic,
    focusTopic
  } = useContext(TopicsContext)

  const [newName, setNewName] = useState<string | undefined>(undefined)

  /**
   * This function handles the renaming of a topic
   *
   * @param newName - The new name of the topic
   */
  const handleEditName = (newName?: string) => {
    if (!newName) {
      node.submit(node.data.name)
      return
    }

    const newNode: Topic = fromTopicTreeDataToTopicConverter({
      ...node.data,
      name: newName
    })
    node.submit(newName)
    editTopic(newNode)

    setNewName(undefined)
  }

  /**
   * This function deletes the topic from the tree and the context
   */
  const handleDelete = () => {
    tree.delete(node.id)
    deleteTopic(fromTopicTreeDataToTopicConverter(node.data))
  }

  /**
   * This function focuses the topic in the context
   * This is used to show the content of the topic in the editor
   */
  useEffect(() => {
    if (node.state.isSelected) {
      focusTopic(node.data.id)
    }
  }, [node.state.isSelected])

  return (
    <div className="node-container" style={style} ref={dragHandle}>
      <div>
        {node.children?.length > 0 ? (
          <span className="arrow" onClick={() => node.isInternal && node.toggle()}>
            {node.isOpen ? <CaretDownFill size={12} /> : <CaretRightFill size={12} />}
          </span>
        ) :
          <span className="arrow">
            <BulletPoint size={10} />
          </span>
        }
      </div>
      <div className="node-text" >
        {node.isEditing ? (
          <input
            type="text"
            defaultValue={node.data.name}
            onFocus={(e) => e.currentTarget.select()}
            onKeyDown={
              (e) => {
                if (e.key === 'Enter') {
                  handleEditName(e.currentTarget.value)
                }
              }
            }
            onChange={(e) => setNewName(e.currentTarget.value)}
            autoFocus
          />
        ) : (
          node.data.name
        )}
      </div>
      <div className="file-actions">
        <div className="folderFileActions">
          {!node.isEditing ? (
            <>
              <button onClick={() => node.edit()} title={t('notepad.rename')}>
                <Pencil />
              </button>
              <button onClick={() => handleDelete()} title={t('notepad.delete')}>
                <Trash />
              </button>
            </>
          ) : (
            <>
              <button onClick={() => {
                handleEditName(newName)
              }} title={t('notepad.save')}>
                <Check />
              </button>
              <button onClick={() => node.reset()} title={t('notepad.cancel')}>
                <X />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

interface TopicTreeProps {
  data: TopicTreeData[],
  treeRef: React.RefObject<any>
}

const TopicTree: FC<TopicTreeProps> = ({
  data,
  treeRef
}) => {
  const { searchQuery, moveTopic } = useContext(TopicsContext)

  const findNodeById = (id: string | null, topics: TopicTreeData[]): TopicTreeData | undefined => {
    if (!id) return
    for (const topic of topics) {
      if (topic.id === id) {
        return topic
      } else if (topic.children && topic.children.length > 0) {
        const foundSubtopic = findNodeById(id, topic.children)

        if (foundSubtopic) {
          return foundSubtopic
        }
      }
    }
    return
  }

  const moveTopics = useCallback((args: {
    dragIds: string[];
    parentId: null | string;
    index: number;
  }) => {
    for (const id of args.dragIds) {
      const topic = findNodeById(id, data)
      const parentTopic = findNodeById(args.parentId, data)

      if (!topic) throw new Error(`Topic with id ${id} not found`)

      // @ts-ignore
      moveTopic(fromTopicTreeDataToTopicConverter(topic), parentTopic?.id, args.index)
    }
  }, [data])

  return (
    <Tree
      ref={treeRef}
      data={data}
      width={'100%'}
      className={classes.tree}
      indent={24}
      rowHeight={32}
      searchTerm={searchQuery}
      onMove={moveTopics}
      openByDefault={false}
    >
      {TopicNode}
    </Tree >
  );
};

interface NotepadBoardProps {
  notepad: Notepad
  onPastedText?: () => void
  closeModal?: () => void
  isLoading?: boolean
}

const NotepadBoard: FC<NotepadBoardProps> = ({
  notepad,
  isLoading = false,
  onPastedText = () => { },
  closeModal = () => { },
}) => {
  const { t } = useTranslation()

  const sessionStorageHighlightedText = sessionStorage.getItem("highlightedText") ?? undefined

  const [highlightedText, setHighlightedText] = useState<string | undefined>(sessionStorageHighlightedText)

  const treeRef = useRef(null);

  const [selectedTopic, setSelectedTopic] = useState<TopicTreeData | undefined>(
    undefined,
  )
  const editedContent = useRef<string | undefined>(undefined)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [notepadTitle, setNotepadTitle] = useState<string>('')

  // Are used to keep the original topics data. It is not affected by the search query
  const [topics, setTopics] = useState<TopicTreeData[]>([])
  // Are used to keep the topics data that is affected by the search query
  const [filteredTopics, setFilteredTopics] = useState<TopicTreeData[]>([])
  const [selectedTopicTitle, setSelectedTopicTitle] = useState<
    string | undefined
  >(undefined)
  const [selectedTopicTags, setSelectedTopicTags] = useState<NotepadTag[]>([])

  const [topicsMenuCollapsed, setTopicsMenuCollapsed] = useState<boolean>(false)

  const { handleSubmit } = useForm()

  const selectedTopicContent = useMemo(() => {
    if (selectedTopic && highlightedText) {
      return selectedTopic.content?.concat(highlightedText)
    }

    return selectedTopic?.content || ''
  }, [selectedTopic, highlightedText])

  /**
   * This function sets the topics data.
   * It sets the topics and the filtered topics to the same data
   *
   * @param data - The topics data
   */
  const setTopicsData = (data: TopicTreeData[]) => {
    setTopics(data)
    setFilteredTopics(data)
  }

  /**
   * This function updates the topics tree
   *
   * @param topic - The topic to update
   * @param data - The topics data
   */
  const updateTopicsTree = (topic: TopicTreeData, data: TopicTreeData[]) => {
    const updatedTopics = [...data]

    const updateTopic = (topic: TopicTreeData, data: TopicTreeData[]) => {
      for (const topicData of data) {
        if (topicData.id === topic.id) {
          topicData.name = topic.name
          topicData.parent = topic.parent
          topicData.children = topic.children
          topicData.content = topic.content
          topicData.tags = topic.tags
          topicData.updated_at = topic.updated_at
          return
        } else if (topicData.children && topicData.children.length > 0) {
          updateTopic(topic, topicData.children)
        }
      }
    }

    updateTopic(topic, updatedTopics)

    setTopicsData(updatedTopics)
  }

  /**
   * This function moves a topic inside the tree
   *
   * @param topicId - The id of the topic to move
   * @param parentId - The id of the new parent
   * @param data - The topics data
   */
  const moveTopicInsideTree = (topicId: string, parentId: string | null, newIndex: number, data: TopicTreeData[]) => {
    // If the topic has a new parent, move the topic to the new parent
    // If the topic has the same parent, do nothing. The Tree component will handle the reordering of the topics for the same level.
    // The order of the topics is not saved in the database
    const updatedTopics = [...data]

    const topicToMove = searchTopicById(data, topicId) ?? null
    const parentTopic = searchTopicById(data, parentId) ?? null

    if (!topicToMove) throw new Error(`Topic with id ${topicId} not found`)

    const removeTopic = (topicId: string, data: TopicTreeData[]) => {
      for (const topic of data) {
        if (topic.id === topicId) {
          const topicIdx = data.findIndex((topic) => topic.id === topicId)
          data.splice(topicIdx, 1)
          return
        } else if (topic.children && topic.children.length > 0) {
          removeTopic(topicId, topic.children)
        }
      }
    }

    removeTopic(topicId, updatedTopics)

    if (parentTopic) {
      parentTopic?.children.splice(newIndex, 0, topicToMove)
      topicToMove.parent = parentTopic.id
    } else {
      updatedTopics.splice(newIndex, 0, topicToMove)
      // @ts-ignore
      topicToMove.parent = null
    }

    setTopicsData(updatedTopics)
  }

  /**
   * This function deletes a topic from the tree
   *
   * @param topicId - The id of the topic to delete
   * @param data - The topics data
   */
  const deleteTopicFromTree = (topicId: string, data: TopicTreeData[]) => {
    const updatedTopics = [...data]

    /**
     * This function deletes a topic from the tree. This function is recursive
     *
     * @param topicId - The id of the topic to delete
     * @param data - The topics data
     */
    const deleteTopic = (topicId: string, data: TopicTreeData[]) => {
      for (const topic of data) {
        if (topic.id === topicId) {
          const topicIdx = data.findIndex((topic) => topic.id === topicId)
          data.splice(topicIdx, 1)
          return
        } else if (topic.children && topic.children.length > 0) {
          deleteTopic(topicId, topic.children)
        }
      }
    }

    deleteTopic(topicId, updatedTopics)

    setTopicsData(updatedTopics)

    setSelectedTopic(undefined)
  }

  // Create, edit and delete topics
  const { mutate: addTopic } = useTopicCreate({
    mutation: {
      onSuccess: (data: Topic) => {
        const newTopic: Topic = {
          id: data.id!,
          notepad: data.notepad,
          index: data.index,
          title: data.title!,
          content: data.content!,
          tags: data.tags,
          updated_at: data.updated_at,
          subtopics: data.subtopics,
        }

        const newTopicTreeData = fromTopicToTopicTreeDataConverter(newTopic)

        setTopicsData([...topics, newTopicTreeData])
      },
    },
  })

  const { mutate: editTopic } = useTopicUpdate()

  const { mutate: moveTopic } = useMoveTopic()

  const { mutate: deleteTopic } = useDeleteTopic()

  // Create and delete tags
  const { mutate: addTag } = useTagCreate({
    mutation: {
      onSuccess: (data: NotepadTag) => {
        const newTag: NotepadTag = {
          id: data.id!,
          title: data.title!,
          topic: data.topic,
        }

        setSelectedTopicTags([...selectedTopicTags!, newTag])
      },
    },
  })

  const { mutate: deleteTag } = useDeleteTag()

  const onAddTag = (title: string) => {
    addTag({
      data: {
        title: title,
        topic: selectedTopic?.id,
      },
    })
  }

  const onDeleteTag = (index: number) => {
    const tagToDelete = selectedTopicTags![index]

    const newTopicTags = selectedTopicTags?.filter((_, i) => i !== index)

    if (selectedTopic?.tags) {
      selectedTopic.tags = newTopicTags
    }

    setSelectedTopicTags(newTopicTags)

    deleteTag({ tagId: tagToDelete?.id! })
  }

  const onSubmit = () => {
    if (selectedTopic) {
      const requestBody: TopicTreeData = {
        ...selectedTopic,
        content: editedContent.current ?? ''
      }
      const editedTopic: Topic = fromTopicTreeDataToTopicConverter(requestBody)

      if (selectedTopic.id) {
        const editedTopicTreeData = fromTopicToTopicTreeDataConverter(editedTopic)

        updateTopicsTree(editedTopicTreeData, topics)

        editTopic({
          topicId: selectedTopic.id!,
          data: editedTopic,
        })
      } else {
        addTopic({ data: editedTopic })
      }

      onPastedText()
      closeModal()
      sessionStorage.removeItem("highlightedText")

      if (editedTopic.content)
        selectedTopic.content = editedTopic.content
      setHighlightedText(undefined)
    }
  }

  const addNewTopic = (title?: string) => {
    const newTopic: Topic = {
      title: title ?? t('notepad.newTopic'),
      notepad: notepad.id!,
      index: topics.length,
      content: '',
      tags: [],
      subtopics: [],
      updated_at: new Date().toISOString(),
    }

    addTopic({ data: newTopic })
  }

  const handleSearch = (query: string) => {
    setSearchQuery(query)
    const filteredTopics = filterTopics(topics, query)
    setFilteredTopics(filteredTopics)
  }

  const filterTopics = (topics: TopicTreeData[], query: string) => {
    const filtered: TopicTreeData[] = []

    const searchInTopicTags = (topic: TopicTreeData) => {
      const tags = topic.tags
      if (tags) {
        const tagTitles = convertObjectArrayIntoStringArray(tags, 'title')

        const found = tagTitles.find((tagTitle) =>
          tagTitle.toLowerCase().includes(query.toLowerCase()),
        )

        return found
      }

      return false
    }

    const searchInTopic = (topic: TopicTreeData) => {
      const tagContent = extractContentFromHTML(topic.content)

      if (
        topic.name?.toLowerCase().includes(query.toLowerCase()) ||
        tagContent.toLowerCase().includes(query.toLowerCase()) ||
        searchInTopicTags(topic)
      ) {
        filtered.push(topic)
      }

      topic.children?.forEach((subtopic) => searchInTopic(subtopic))
    }

    topics.forEach((topic) => searchInTopic(topic))

    return filtered
  }

  const searchTopicById = (
    topics: TopicTreeData[] | undefined,
    id: string | null,
  ): TopicTreeData | undefined => {
    if (!topics) return

    for (const topic of topics) {
      if (topic.id === id) {
        return topic
      } else if (topic.children && topic.children.length > 0) {
        const foundSubtopic: TopicTreeData | undefined = searchTopicById(
          topic.children,
          id,
        )
        if (foundSubtopic) {
          return foundSubtopic
        }
      }
    }
    return
  }

  const getHighlightedHTML = (html: string, searchQuery: string) => {
    const regex = new RegExp(`(${searchQuery})`, 'gi')

    return html.replace(regex, `<mark style="padding: 0 !important;">$1</mark>`)
  }

  const onSearchedTopicClick = (topicId: string) => {
    const selectedTopic = searchTopicById(filteredTopics, topicId)
    setSelectedTopic(selectedTopic)
    setFilteredTopics(topics)
    setSearchQuery('')
  }

  const sortTopicsByTitle = (topics: TopicTreeData[], reverse?: boolean): TopicTreeData[] => {
    let reverseFactor = 1

    if (reverse) {
      reverseFactor = -1
    }

    return topics
      .map((topic) => ({
        ...topic,
        children: sortTopicsByTitle(topic.children ?? []),
      }))
      .sort((a, b) => {
        if (a.name! < b.name!) {
          return -1 * reverseFactor
        }
        if (a.name! > b.name!) {
          return 1 * reverseFactor
        }
        return 0
      })
  }

  useEffect(() => {
    if (selectedTopic) {
      setSelectedTopicTitle(selectedTopic.name)
      setSelectedTopicTags(selectedTopic?.tags ?? [])
    }
  }, [selectedTopic])

  useEffect(() => {
    if (selectedTopic && highlightedText) {
      editedContent.current = selectedTopic.content?.concat(
        `<br/><br/><strong>${highlightedText}</strong>`,
      )
    }
  }, [selectedTopic, highlightedText])

  useEffect(() => {
    const topicsTreeData = fromTopicsDataToTopicTreeDataConverter(notepad.topics ?? [])

    setTopicsData(topicsTreeData)

    setNotepadTitle(notepad.title!)
  }, [notepad])

  const TopicsContextValue: TopicsContextProps = {
    searchQuery,
    setData: setTopicsData,
    moveTopic: (topic, newParent, newIndex) => {
      moveTopicInsideTree(topic.id!, newParent ?? null, newIndex, topics)

      moveTopic({
        topicId: topic.id!, data: {
          // @ts-ignore
          new_parent: newParent ?? null,
          new_index: newIndex,
        }
      })
    },
    editTopic: (topic) => {
      updateTopicsTree(fromTopicToTopicTreeDataConverter(topic), topics)
      editTopic({ topicId: topic.id!, data: topic })
    },
    deleteTopic: (topic) => {
      deleteTopicFromTree(topic.id!, topics)
      deleteTopic({ topicId: topic.id! })
    },
    focusTopic: (topicId) => {
      const selectedTopic = searchTopicById(topics, topicId)
      setSelectedTopic(selectedTopic)
    }
  }

  return (
    <>
      {!isLoading ? (
        <>
          <Row>
            <Col className={topicsMenuCollapsed ? classes.collapsed : classes.expanded} lg={3}>
              {
                !topicsMenuCollapsed && (
                  <>
                    <Row className="mb-4 align-items-center">
                      <Col className="d-flex align-items-center justify-content-between"
                      >
                        <Col className="d-flex">
                          <Typography
                            variant="h4"
                            fontWeight="light"
                            className="mb-0 dark-text"
                          >
                            {notepadTitle}
                          </Typography>
                        </Col>
                        <Col>
                          <SearchBar
                            searchQuery={searchQuery}
                            onSearch={handleSearch}
                            className={classes.searchBar}
                            placeholderText={t('notepad.searchBarPlaceholder')}
                          />
                        </Col>
                        <Col md={1} lg={1} className="d-flex justify-content-end">
                          <Button
                            variant="white"
                            className="text-dark p-0"
                            onClick={() => {
                              addNewTopic()
                            }}
                          >
                            <Plus size={20} title={t('notepad.addNewTopic')} />
                          </Button>
                        </Col>
                      </Col>
                    </Row>
                    {filteredTopics.length > 0 && (
                      <TopicsContext.Provider value={TopicsContextValue}>
                        <TopicTree data={filteredTopics} treeRef={treeRef} />
                      </TopicsContext.Provider>
                    )}
                  </>
                )
              }
            </Col>
            {
              selectedTopic && (
                <Col className='col-auto d-md-none d-lg-block'>
                  <Button
                    variant="white"
                    className="text-dark p-0"
                  >
                    {
                      topicsMenuCollapsed ?
                        <ArrowBarRight size={12}
                          onClick={() => setTopicsMenuCollapsed(false)}
                          title={t('notepad.expandTopics')} /> :
                        <ArrowBarLeft size={12}
                          onClick={() => setTopicsMenuCollapsed(true)}
                          title={t('notepad.collapseTopics')} />
                    }
                  </Button>
                </Col>
              )
            }
            <Col className={classes.textEditorWrapper}>
              {!searchQuery ?
                filteredTopics.length === 0 ? (
                  <Row
                    className="mb-2 align-items-center justify-content-center text-center"
                    style={{ height: '40vh', width: '100%' }}
                  >
                    <Typography
                      variant="h5"
                    >
                      {t('notepad.noTopicsMessage')}
                    </Typography>
                  </Row>
                ) :

                  selectedTopic && (
                    <>
                      <Row
                        className="mb-2 align-items-center"
                        style={{ height: 40 }}
                      >
                        <Col>
                          <Typography variant="h3" className="mb-0">
                            {selectedTopicTitle}
                          </Typography>
                        </Col>
                      </Row>
                      <RichTextEditor
                        contentId={selectedTopic.id}
                        initialContent={selectedTopicContent}
                        onContentChange={(value) => (editedContent.current = value)}
                        onSubmit={handleSubmit(onSubmit)}
                        onDiscardChanges={() => closeModal()}
                        placeholderText={t('notepad.placeholderText')}
                        setHighlightedText={setHighlightedText}
                        withImages={true}
                      />
                      <TagsInput
                        parent={selectedTopic?.id}
                        tags={convertObjectArrayIntoStringArray(
                          selectedTopicTags,
                          'title',
                        )}
                        placeholder={t('notepad.insertTagsPlaceholder')}
                        onAddTag={onAddTag}
                        onDeleteTag={onDeleteTag}
                      />
                    </>
                  ) : (
                  <div className={classes.searchTopicsList}>
                    {filteredTopics.map((topic) => (
                      <>
                        <div
                          key={topic.id}
                          className={clsx(classes.searchTopicBlock)}
                          onClick={() => onSearchedTopicClick(topic.id!)}
                        >
                          <Typography variant="h3">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: getHighlightedHTML(
                                  topic.name!,
                                  searchQuery,
                                ),
                              }}
                            />
                          </Typography>
                          <Typography variant="p" className="mb-0">
                            <div
                              className={classes.searchTopicTextContent}
                              dangerouslySetInnerHTML={{
                                __html: getHighlightedHTML(
                                  extractContentFromHTML(topic.content!),
                                  searchQuery,
                                ),
                              }}
                            />
                          </Typography>
                          <div className={clsx('mt-4')}>
                            <TagBootstrap size={18} className="me-2" />
                            {topic.tags?.map(({ title }) => (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: getHighlightedHTML(title, searchQuery),
                                }}
                                className={classes.tagItem}
                              />
                            ))}
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  </div>
                )
              }
            </Col>
          </Row>
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default NotepadBoard
