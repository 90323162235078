import clsx from 'clsx'
import * as material from 'material-colors'
import { FC, useEffect, useMemo, useState } from 'react'
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useDeckCreate,
  useDeckUpdate,
  useDeleteDeck,
  useTagsList
} from '../../api/flashcards/flashcards'
import { Deck, Flashcard, Tag } from '../../api/model'
import { Typography } from '../../atoms'
import classes from './FlashcardsBoard.module.css'
// From https://casesandberg.github.io/react-color/
import { FilePlus, Files } from 'react-bootstrap-icons'
import SearchBar from '../../atoms/SearchBar/SearchBar'
import { EditDeckModal, FlashcardBoard, FlashcardsFilterDropdown } from '../../molecules'
import './FlashcardsBoard.css'
import FlashcardsDeckDisplay from './FlashcardsDeckDisplay'

const defaultDeckColor = material.lightBlue['500'].toString()

type FormValues = {
  deckName: string
}

interface FlashcardsBoardProps {
  collectionId: string
  decks: Deck[]
  addMode?: boolean
  highlightedText?: string
  initialEditMode?: boolean
  editDeckMode?: boolean
  selectingMode?: boolean
  selectedFlashcardsIds?: string[]
  setSelectedFlashcardsIds?: (selectedFlashcardsIds: string[]) => void
  onCloseFlashcard?: () => void
}

const FlashcardsBoard: FC<FlashcardsBoardProps> = ({
  collectionId,
  decks,
  highlightedText,
  addMode = true,
  initialEditMode = false,
  editDeckMode = false,
  selectingMode = false,
  selectedFlashcardsIds = [],
  setSelectedFlashcardsIds = () => { },
  onCloseFlashcard = () => { },
}) => {
  const { t } = useTranslation()

  const { handleSubmit } = useForm()

  const [flashcardsDecks, setFlashcardsDecks] = useState<Deck[]>([])
  const [showFlashcardBoardModal, setShowFlashcardBoardModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deckIdxSelected, setDeckIdxSelected] = useState<number | undefined>(0)
  const [deckName, setDeckName] = useState<string | undefined>(undefined)
  const [deckColor, setDeckColor] = useState<string | undefined>(
    defaultDeckColor,
  )
  const [duplicatedDeckName, setDuplicatedDeckName] = useState<boolean>(false)
  const [flashcardIdSelected, setFlashcardIdSelected] = useState<
    string | undefined
  >(undefined)

  const [checkedTags, setCheckedTags] = useState<string[]>([])

  const [showColorPicker, setShowColorPicker] = useState(false)

  // If true, the user can edit the current flashcard's attributes
  const [editMode, setEditMode] = useState(initialEditMode)
  // If true, it only shows one side of the card at the time
  const [singleSideView, setSingleSideView] = useState(true)

  const [frontSideText, setFrontSideText] = useState<string | undefined>(
    undefined,
  )

  const [backSideText, setBackSideText] = useState<string | undefined>(
    undefined,
  )

  const [showFrontOrBacksideModal, setShowFrontOrBacksideModal] =
    useState(false)

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectFavorites, setSelectFavorites] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)

  const { mutate: deleteDeckAPI } = useDeleteDeck({
    mutation: {
      onSuccess: () => {
        deleteDeck()
      },
    },
  })

  const { mutate: createDeckAPI } = useDeckCreate({
    mutation: {
      onSuccess: (data) => {
        flashcardsDecks.push(data)
        onDeckChanged()
      },
    },
  })

  const { mutate: updateDeckAPI } = useDeckUpdate({
    mutation: {
      onSuccess: (data) => {
        currentFlashcardsDeck!.name = data.name
        currentFlashcardsDeck!.color = data.color
        onDeckChanged()
      },
    },
  })

  const {
    data: tagsData,
    isLoading: isLoadingTagsData,
    refetch: refetchTags,
  } = useTagsList();
  /*
  const tags = useMemo(() => {
    return tagsData?.results ?? []
  }, [tagsData])
  */
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    setTags(tagsData?.results ?? []);
  }, [tagsData]);

  const onDeckChanged = () => {
    setShowEditModal(false)
  }

  const handleClose = () => onCloseFlashcardBoardModal()

  const onEditDeckClicked = (index?: number) => {
    setDeckIdxSelected(index)
    setShowEditModal(true)
  }

  const onDeleteDeckClicked = (index: number) => {
    setShowDeleteModal(true)
    setDeckIdxSelected(index)
  }

  const createNewDeck = () => {
    setDeckIdxSelected(undefined)
    setShowEditModal(true)
  }

  const onCancelCreateDeck = () => {
    setShowColorPicker(false)
    setShowEditModal(false)
    setDuplicatedDeckName(false)
    if (flashcardsDecks.length > 0) {
      setDeckIdxSelected(undefined)
    }
  }

  const onFlashcardFrontsideSelect = () => {
    setFrontSideText(highlightedText)
    showFlashcardBoard(deckIdxSelected, flashcardIdSelected)
    setShowFrontOrBacksideModal(false)
  }

  const onFlashcardBacksideSelect = () => {
    setBackSideText(highlightedText)
    showFlashcardBoard(deckIdxSelected, flashcardIdSelected)
    setShowFrontOrBacksideModal(false)
  }

  const checkIfNameAlredyInUse = (newName: string, deck?: Deck) => {
    let deckNameAlreadyInUse = false

    // check if the decks name is already in use and it's not the same deck
    if (!deck) {
      deckNameAlreadyInUse = flashcardsDecks.some(
        (item) => item.name === newName,
      )
    } else {
      deckNameAlreadyInUse = flashcardsDecks.some(
        (item) => item.name === newName && item.id !== deck.id,
      )
    }

    return deckNameAlreadyInUse
  }

  const onSubmitFlashcardsDeckEdit: SubmitHandler<FormValues> = async () => {
    if (!deckName) {
      return
    }

    const color = deckColor ?? defaultDeckColor

    const deckNameAlreadyInUse = checkIfNameAlredyInUse(
      deckName,
      currentFlashcardsDeck,
    )

    if (deckNameAlreadyInUse) {
      setDuplicatedDeckName(true)
      return
    }

    setDuplicatedDeckName(false)

    // If it's editing an existing flashcards deck
    if (currentFlashcardsDeck) {
      updateDeckAPI({
        deckId: currentFlashcardsDeck!.id!,
        data: { ...currentFlashcardsDeck, name: deckName, color: color },
      })
    }
    // If it's creating a new flashcards deck
    else {
      const newDeck: Partial<Deck> = {
        name: deckName,
        color: color,
        flashcards: [],
        collection: collectionId,
        last_studied: new Date().toISOString(),
      }

      createDeckAPI({ data: newDeck })
    }
  }

  const filterFlashcards = (flashcards: Flashcard[], query: string) => {
    return flashcards.filter((flashcard) => {
      const frontsideText = flashcard.frontside_text ?? ''
      const backsideText = flashcard.backside_text ?? ''
      return (
        frontsideText.toLowerCase().includes(query.toLowerCase()) ||
        backsideText.toLowerCase().includes(query.toLowerCase())
      )
    })
  }

  const handleFilterFlashcardsbyQuery = (
    flashcards: Flashcard[],
    query: string,
  ) => {
    if (!query) {
      return flashcards
    }

    const filteredFlashcards = filterFlashcards(flashcards, query)
    return filteredFlashcards
  }

  const handleFilterFlashcardsByFavorites = (flashcards: Flashcard[]) => {
    return flashcards.filter((flashcard) => flashcard.favorite)
  }

  const handleFilterFlashcardsByTags = (flashcards: Flashcard[]) => {
    const checkIfStringExists = (array: string[], tags: Tag[]): boolean => {
      return tags.some((obj) => array.includes(obj.title!.toString()))
    }

    const filteredTags = tags.filter((tag) => checkedTags.includes(tag.id!))

    const tagsTitles = filteredTags.map((tag) => tag.title!)

    const filteredFlashcards = flashcards.filter((flashcard) => {
      if (!flashcard.tags) return false
      return checkIfStringExists(tagsTitles, flashcard.tags)
    })

    return filteredFlashcards
  }

  const filteredFlashcardsDecks = useMemo(() => {
    let filteredFlashcardsDecks = flashcardsDecks

    if (searchQuery) {
      filteredFlashcardsDecks = flashcardsDecks.map((flashcardsDeck) => {
        let filteredFlashcards = handleFilterFlashcardsbyQuery(
          flashcardsDeck.flashcards,
          searchQuery,
        )

        return { ...flashcardsDeck, flashcards: filteredFlashcards }
      })

      filteredFlashcardsDecks = filteredFlashcardsDecks.filter(
        (flashcardsDeck) => flashcardsDeck.flashcards.length > 0,
      )
    }

    if (selectFavorites) {
      filteredFlashcardsDecks = flashcardsDecks.map((flashcardsDeck) => {
        let filteredFlashcards = handleFilterFlashcardsByFavorites(
          flashcardsDeck.flashcards,
        )

        return { ...flashcardsDeck, flashcards: filteredFlashcards }
      })
    }

    if (checkedTags.length > 0) {
      filteredFlashcardsDecks = flashcardsDecks.map((flashcardsDeck) => {
        let filteredFlashcards = handleFilterFlashcardsByTags(
          flashcardsDeck.flashcards,
        )

        return { ...flashcardsDeck, flashcards: filteredFlashcards }
      })
    }

    return filteredFlashcardsDecks
  }, [flashcardsDecks, searchQuery, selectFavorites, checkedTags])

  const currentFlashcardsDeck = useMemo(() => {
    if (deckIdxSelected === undefined) {
      return undefined
    }
    return filteredFlashcardsDecks[deckIdxSelected]
  }, [filteredFlashcardsDecks, deckIdxSelected])

  const flashcardIdxSelected = useMemo(() => {
    if (flashcardIdSelected === undefined) {
      return undefined
    }

    return currentFlashcardsDeck?.flashcards.findIndex(
      (flashcard) => flashcard.id === flashcardIdSelected,
    )
  }, [currentFlashcardsDeck, flashcardIdSelected])

  const hasFrontOrBackside = useMemo(() => {
    return highlightedText ? true : false
  }, [highlightedText])

  const totalNumberOfFlashcards = useMemo(() => {
    let total = 0
    flashcardsDecks.map((flashcardsDeck) => {
      total += flashcardsDeck.flashcards.length
    })
    return total
  }, [flashcardsDecks])

  const deleteDeck = () => {
    const newFlashcardsDeck = flashcardsDecks.filter(
      (item, index) => index !== deckIdxSelected,
    )
    setFlashcardsDecks(newFlashcardsDeck)
    setShowDeleteModal(false)
    setDeckIdxSelected(undefined)
  }

  const onCloseFlashcardBoardModal = () => {
    setShowFlashcardBoardModal(false)
    setSingleSideView(true)
    setEditMode(initialEditMode)
    onCloseFlashcard()
  }

  const onAddFlashcard = (deckIdx?: number, flashcardId?: string) => {
    setDeckIdxSelected(deckIdx ?? undefined)
    setFlashcardIdSelected(flashcardId ?? undefined)

    if (hasFrontOrBackside) {
      setShowFrontOrBacksideModal(true)
      setEditMode(true)
    } else {
      showFlashcardBoard(deckIdx, flashcardId)
    }
  }

  const showFlashcardBoard = (deckIdx?: number, flashcardId?: string) => {
    // If there is no deckIdx and flashcardIdx, it means that the user wants to create a new flashcard
    if (deckIdx === undefined || flashcardId === undefined) {
      setEditMode(true)
    }

    setShowFlashcardBoardModal(true)
  }

  const onSelectFlashcard = (flashcardId: string) => {
    if (selectedFlashcardsIds.includes(flashcardId)) {
      const newSelectedFlashcardsIds = selectedFlashcardsIds.filter(
        (id) => id !== flashcardId,
      )
      setSelectedFlashcardsIds(newSelectedFlashcardsIds)
    } else {
      const newSelectedFlashcardsIds = [...selectedFlashcardsIds, flashcardId]
      setSelectedFlashcardsIds(newSelectedFlashcardsIds)
    }
  }

  const flashcardSelected = (flashcardId: string) => {
    return selectedFlashcardsIds.includes(flashcardId)
  }

  const onSelectAllFlashcardsFromADeck = (deckIdx: number) => {
    if (isAllFlashcardsSelectedFromDeck(deckIdx)) {
      const newSelectedFlashcardsIds = selectedFlashcardsIds.filter(
        (id) => {
          const deck = filteredFlashcardsDecks[deckIdx]
          if (!deck) return false
          return !deck.flashcards.map((flashcard) => flashcard.id!).includes(id)
        },
      )
      setSelectedFlashcardsIds(newSelectedFlashcardsIds)
    } else {
      const newSelectedFlashcardsIds = [...selectedFlashcardsIds]
      filteredFlashcardsDecks[deckIdx].flashcards.forEach((flashcard) => {
        if (!selectedFlashcardsIds.includes(flashcard.id!)) {
          newSelectedFlashcardsIds.push(flashcard.id!)
        }
      })
      setSelectedFlashcardsIds(newSelectedFlashcardsIds)
    }
  }

  const onSelectAllFlashcards = () => {
    if (selectedFlashcardsIds.length === totalNumberOfFlashcards) {
      setSelectedFlashcardsIds([])
      setSelectedAll(false)
    } else {
      const newSelectedFlashcardsIds = [...selectedFlashcardsIds]
      flashcardsDecks.forEach((flashcardsDeck) => {
        flashcardsDeck.flashcards.forEach((flashcard) => {
          if (!selectedFlashcardsIds.includes(flashcard.id!)) {
            newSelectedFlashcardsIds.push(flashcard.id!)
          }
        })
      })
      setSelectedFlashcardsIds(newSelectedFlashcardsIds)
      setSelectedAll(true)
    }
  }

  const isAllFlashcardsSelectedFromDeck = (deckIdx: number) => {
    // Check if the ids from the deck are all in the selectedFlashcardsIds
    const deck = filteredFlashcardsDecks[deckIdx]
    if (!deck) return false
    const deckFlashcardsIds = deck.flashcards.map((flashcard) => flashcard.id!)
    return deckFlashcardsIds.every((id) => selectedFlashcardsIds.includes(id))
  }

  const handleContextRefetch = async () => {
    try {
      await refetchTags();
      console.log('Tags refetched successfully');
    } catch (error) {
      console.error('Error refetching tags:', error);
    }
  };
  useEffect(() => {
    if (flashcardsDecks.length === 0) {
      setDeckIdxSelected(undefined)
      setDeckName(undefined)
    }
  }, [flashcardsDecks])

  useEffect(() => {
    setDeckName(currentFlashcardsDeck?.name)
    setDeckColor(currentFlashcardsDeck?.color ?? defaultDeckColor)
  }, [currentFlashcardsDeck])

  useEffect(() => {
    if (decks) {
      setFlashcardsDecks(decks)
    }
  }, [decks])


  return (
    <>
      <Row className="mb-4">
        <Col className="d-flex align-items-center col-auto">
          <SearchBar
            placeholderText={t('flashcards.searchFlashcards')}
            className={clsx(classes.searchBar, 'me-2')}
            onSearch={setSearchQuery}
            searchQuery={searchQuery}
          />
          <FlashcardsFilterDropdown
            tags={tags}
            onCheckedTagsChange={setCheckedTags}
            selectFavorites={selectFavorites}
            setSelectFavorites={setSelectFavorites}
          />
        </Col>
        {selectingMode && (
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className={'me-2'}
              onClick={() => onSelectAllFlashcards()}
            >
              {selectedFlashcardsIds.length === totalNumberOfFlashcards
                ? t('common.deselectAll')
                : t('common.selectAll')}
            </Button>
            <Button
              variant="dark"
              className={'me-2'}
              onClick={() => {
                onCloseFlashcard()
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button variant="success" type="submit">
              {t('common.create')}
            </Button>
          </Col>
        )}
        {addMode && (
          <Col className={clsx(classes.addContentContainer)}>
            <OverlayTrigger
              key="add-new-deck-tooltip"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-view-deck`}>
                  {t('flashcards.addFlashcardDeck')}
                </Tooltip>
              }
            >
              <Button
                variant="none"
                onClick={() => createNewDeck()}
                data-testid="add-new-deck-button"
              >
                <Files size={18} />
              </Button>
            </OverlayTrigger>
            {flashcardsDecks.length > 0 ? (
              <OverlayTrigger
                key="add-new-flashcard-tooltip"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-view-flashcard`}>
                    {t('flashcards.addFlashcard')}
                  </Tooltip>
                }
              >
                <Button
                  variant="none"
                  onClick={() => onAddFlashcard()}
                  data-testid="add-new-flashcard-button"
                >
                  <FilePlus size={18} />
                </Button>
              </OverlayTrigger>

            ) : null}
          </Col>
        )}
      </Row>
      <div className={clsx(classes.flashcardsBoard)}>
        {
        filteredFlashcardsDecks && filteredFlashcardsDecks.length > 0
        ? (filteredFlashcardsDecks.map((flashcardsDeck, deckIdx) => (
          <>
          <FlashcardsDeckDisplay
            key={flashcardsDeck.id!}
            deck={flashcardsDeck}
            index={deckIdx}
            searchQuery={searchQuery}
            addMode={addMode}
            selectingMode={selectingMode}
            editDeckMode={editDeckMode}
            onEditDeckClicked={onEditDeckClicked}
            onDeleteDeckClicked={onDeleteDeckClicked}
            onSelectAllFlashcardsFromADeck={onSelectAllFlashcardsFromADeck}
            isAllFlashcardsSelectedFromDeck={isAllFlashcardsSelectedFromDeck}
            onAddFlashcard={onAddFlashcard}
            onSelectFlashcard={onSelectFlashcard}
            flashcardSelected={flashcardSelected}
            onContextRefetch={handleContextRefetch}
          />
          </>
        )))
        : (
          <div
            className={clsx(
              classes.flashcardsBoard,
              'd-flex justify-content-center align-items-center',
            )}
          >
            <Typography variant="h5">{t('flashcards.noFlashcards')}</Typography>
          </div>
        )}
      </div>
      {showFlashcardBoardModal && (
        <FlashcardBoard
          show={showFlashcardBoardModal}
          flashcardsDecks={flashcardsDecks}
          currentFlashcardsDeckIdx={deckIdxSelected}
          currentFlashcardsDeck={currentFlashcardsDeck}
          initialFlashcardIdx={flashcardIdxSelected}
          initialFrontsideText={frontSideText}
          initialBacksideText={backSideText}
          editMode={editMode}
          singleSideView={singleSideView}
          setFlashcardsDecks={setFlashcardsDecks}
          handleClose={handleClose}
          setEditMode={setEditMode}
          setSingleSideView={setSingleSideView}
        />
      )}
      <Modal
        show={showFrontOrBacksideModal}
        onHide={() => setShowFrontOrBacksideModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('exam.flashcards.frontOrBackTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('exam.flashcards.frontOrBackText')}</p>
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button
              variant="dark"
              onClick={() => {
                onFlashcardFrontsideSelect()
              }}
            >
              {t('flashcards.front')}
            </Button>
            <Button
              variant="dark"
              onClick={() => {
                onFlashcardBacksideSelect()
              }}
            >
              {t('flashcards.back')}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
      <EditDeckModal
        showEditModal={showEditModal}
        deckIdxSelected={deckIdxSelected}
        currentFlashcardsDeck={currentFlashcardsDeck}
        onSubmit={handleSubmit(onSubmitFlashcardsDeckEdit)}
        onCancelCreateDeck={onCancelCreateDeck}
        duplicatedDeckName={duplicatedDeckName}
        deckName={deckName}
        setDeckName={setDeckName}
        deckColor={deckColor}
        setDeckColor={setDeckColor}
        showColorPicker={showColorPicker}
        setShowColorPicker={setShowColorPicker}
        setDuplicatedDeckName={setDuplicatedDeckName}
      />
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{t('flashcards.deleteFlashcardDeck')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('flashcards.deleteFlashcardDeckDescription')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              setShowDeleteModal(false)
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              deleteDeckAPI({ deckId: currentFlashcardsDeck!.id! })
            }
          >
            {t('common.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FlashcardsBoard
