import { useMutation, useQuery} from 'react-query';
import { instance } from '../instance';


type moveFlashcardsPayload = {
    flashcards_ids: string[],
    deck?: string,
    collection?: string,

}

type moveDeckPayload = {
    deck?: string,
    collection?: string,

}

type deckCreatePayload = {
    name: string,
    collection: string,
    color: string,
}

export const moveFlashcards = (data: moveFlashcardsPayload) => {
    return instance(
          {url: '/flashcards/move/', method: 'PATCH',
          headers: {'Content-Type': 'application/json', },
          data
        },);
}

export const moveDeck = (data: moveDeckPayload) => {
    return instance(
          {url: '/flashcards/move/deck/', method: 'PATCH',
          headers: {'Content-Type': 'application/json', },
          data
        },);
}

export const getListDecks = () => {
    return instance(
          {url: '/flashcards/decks/create/list/', method: 'GET',
          headers: {'Content-Type': 'application/json', },
        },);
}

export const createDeck = (data: deckCreatePayload) => {
    return instance(
          {url: '/flashcards/decks/create/list/', method: 'POST',
          headers: {'Content-Type': 'application/json', },
          data
        },);
}

export const useDeckList = () => {
    return useQuery({queryKey: 'deckList', queryFn: getListDecks});
}

export const useCreateDeck = () => {
    return useMutation({mutationFn: (data: deckCreatePayload) => createDeck(data)});
}

export const useMoveDeck = () => {
    return useMutation({mutationFn: (data: moveDeckPayload) => moveDeck(data)});
}

export const useMoveFlashcards = () => {
    return useMutation({mutationFn: (data: moveFlashcardsPayload) => moveFlashcards(data)});
}
