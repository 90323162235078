import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Overlay from 'react-bootstrap/Overlay';
import type { Deck } from '../../api/model';
import classes from './FlashcardsBoard.module.css';
import clsx from 'clsx';
import { Typography } from '../../atoms';
import { Button, Row, Col, Card, Tooltip, OverlayTrigger, Modal, Form, CloseButton } from 'react-bootstrap';
import { DeckTitleDisplay } from '../../molecules';
import { useEffect, useState } from 'react';
import { ArrowsCollapse, ArrowsExpand, BoxArrowRight, Check2All, CheckAll, Eraser, FolderSymlink, PlusSquare, X } from 'react-bootstrap-icons';
import { useMoveDeck, useMoveFlashcards } from '../../api/manual/useFlashcard';
import { useFlashcardsCollectionsList } from '../../api/flashcards/flashcards';
import { useDeckList, useCreateDeck } from '../../api/manual/useFlashcard';
import UpgradePopupModal from '../../atoms/upgradePopup/UpgradePopupModal';



type FlashcardsDeckDisplayProps = {
  deck: Deck,
  index: number,
  searchQuery: string,
  addMode?: boolean,
  selectingMode?: boolean,
  editDeckMode?: boolean,
  onEditDeckClicked: (index: number) => void,
  onDeleteDeckClicked: (index: number) => void,
  onSelectAllFlashcardsFromADeck: (index: number) => void,
  isAllFlashcardsSelectedFromDeck: (index: number) => boolean,
  onAddFlashcard: (deckIdx?: number, flashcardId?: string) => void,
  onSelectFlashcard: (flashcardId: string) => void,
  flashcardSelected: (flashcardId: string) => boolean,
  flashcardsToMove?: string[],
  flashcardsToMoveState?: boolean,
  onContextRefetch?: () => void,
};

function FlashcardsDeckDisplay({
  deck,
  index,
  searchQuery,
  addMode,
  selectingMode,
  editDeckMode,
  onEditDeckClicked,
  onDeleteDeckClicked,
  onSelectAllFlashcardsFromADeck,
  isAllFlashcardsSelectedFromDeck,
  onAddFlashcard,
  onSelectFlashcard,
  flashcardSelected,
}: FlashcardsDeckDisplayProps) {
  const methods = useForm(
    {
      defaultValues: {
        collection: '',
        deck: '',
        selectedFlashcards: [],
      },
    },
  );
  const deckForm = useForm(
    {
      defaultValues: {
        collection: methods.watch('collection') ?? '',
        name: '',
        color: '',
      },
    },
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [flashcardsToMoveState, setFlashcardsToMoveState] = useState<boolean>(false);
  const [deckToMove, setDeckToMove] = useState<string | null>(null);
  const [deckModal, setDeckModal] = useState<boolean>(false);
  const [filteredDeck, setFilteredDeck] = useState<Deck[]>([]);
  const { mutate: moveFlashcards, data: moveFlashcardsData } = useMoveFlashcards();
  const {mutate: moveDeck, data: moveDeckData} = useMoveDeck();
  const { mutate: DeckMutate, data: DeckCreateData } = useCreateDeck();
  const {data: CollectionsData} = useFlashcardsCollectionsList();
  const {data: DecksData, refetch: deckRefetch} = useDeckList();





  const onDeckToMove = (deckId: string) => {
      setDeckToMove(deckId);
      setModalOpen(!modalOpen);
  };

  const onMoveFlashcards = (data) => {
    const flashcardPayload = {
      collection: data.collection,
      deck: data.deck,
      flashcard_ids: methods.watch('selectedFlashcards'),
    }

    const deckPayload = {
      collection: data.collection,
      deck: deckToMove,
    }

    if (deckToMove) {
      console.log(deckPayload);
      moveDeck(deckPayload);
    } else {
      moveFlashcards(flashcardPayload);
    }
  }


  const onSubmitDeck = (data) => {
      const payload = {
        name: data.name,
        color: data.color,
        collection: methods.watch("collection"),
      }
      DeckMutate(payload);
  };

  useEffect(() => {
    if (methods.watch('deck') === 'new') {
      setDeckModal(true);
    }
  }, [methods.watch('deck')]);

  useEffect(() => {
    deckRefetch();
    setFilteredDeck(Array.isArray(DecksData) ? DecksData.filter((item: Deck) => item.collection === methods.watch("collection")) : []);
    setDeckModal(false);
  }, [DeckCreateData, DecksData, methods.watch('collection')]);

  const onSetMoveState = () => {
      setFlashcardsToMoveState(prevState => !prevState);
      methods.setValue('selectedFlashcards', []);
      setDeckToMove(null);
  };


  useEffect(() => {
    if (moveFlashcardsData || moveDeckData) {
      navigate(0);
    }
  } , [moveFlashcardsData, moveDeckData]);

  const onSelectMoveFlashcard = (flashcardId: string) => {
    if (methods.watch('selectedFlashcards').includes(flashcardId)) {
      methods.setValue('selectedFlashcards', methods.watch('selectedFlashcards').filter((id) => id !== flashcardId));
    } else {
      methods.setValue('selectedFlashcards', [...methods.watch('selectedFlashcards'), flashcardId]);
    }
  };
  const onSelectAllFlashcards = () => {
    if (methods.watch('selectedFlashcards').length === deck.flashcards.length) {
      methods.setValue('selectedFlashcards', []);
    } else {
      let a: string[] = [];
      const allFlashcardIds = deck.flashcards.map((flashcard) => a.push(flashcard.id));
      methods.setValue('selectedFlashcards', a);
    }

  };

  const renderTooltip = (children: string) => (
    <Tooltip id="button-tooltip">
      {children}
    </Tooltip>
  );

  if (!addMode && deck.flashcards.length === 0) return null
  return (
    <>
      <div className="d-flex align-items-left mb-3">
        <DeckTitleDisplay
          deckIdx={index}
          name={deck.name}
          count={deck.flashcards.length}
          color={deck.color ?? ''}
          editMode={editDeckMode}
          onEditDeckClicked={onEditDeckClicked}
          onDeleteDeckClicked={onDeleteDeckClicked}
          onOpenModal={() => onDeckToMove(deck.id!)}
          onMoveFlashcardsState={onSetMoveState}
        />
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {t(`flashcards.${ collapsed ? "expand" : "collapse"}`)}
            </Tooltip>
          }
        >
          <Button variant='none' onClick={() => setCollapsed((v) => !v)}>
            { collapsed ? <ArrowsExpand size={18} /> : <ArrowsCollapse size={18}/> }
          </Button>
        </OverlayTrigger>
        {selectingMode && (
          <Button
            variant="link"
            onClick={() => onSelectAllFlashcardsFromADeck(index)}
          >
            {isAllFlashcardsSelectedFromDeck(index)
              ? t('common.deselectAll')
              : t('common.selectAll')}
          </Button>
        )}
      </div>
      {
        !collapsed && (
          <Row
            className={clsx(
              classes.flashcardsContainer,
              'align-items-left',
            )}
          >

            {flashcardsToMoveState && (
              <>

                <Col xs="12" md="12" lg="12" style={{display: 'flex', marginBottom: 10, alignItems: 'center'}}>
                  {flashcardsToMoveState && (
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Cancelar')}>
                      <X onClick={onSetMoveState} style={{color: 'grey'}} size={20} />
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Selecionar Todas')}>
                    <Check2All onClick={onSelectAllFlashcards} style={{marginLeft: 10, color: 'grey'}} size="20" />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Desselecionar todas')}>
                      <Eraser size={20} style={{marginLeft: 10, color: 'grey'}} onClick={() => methods.setValue('selectedFlashcards', [])}  />
                    </OverlayTrigger>
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Mover Flashcards')}>
                      <FolderSymlink size={20} style={{marginLeft: 10, color: 'grey'}} onClick={() => setModalOpen(!modalOpen)} />
                  </OverlayTrigger>
                </Col>
                </>
            )}

            {deck.flashcards.map((flashcard) => (
              <Col className={clsx(classes.flashcardItem, 'col-2')}>
                <Card
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  className={clsx(
                    selectingMode && flashcardSelected(flashcard.id!)
                      ? 'border border-3 border-success'
                      : null,
                  )}
                  onClick={() => {
                    if (selectingMode) {
                      onSelectFlashcard(flashcard.id!)
                    }
                    else if (flashcardsToMoveState) {
                      onSelectMoveFlashcard(flashcard.id!);
                    } else {
                      onAddFlashcard(index, flashcard.id)
                    }
                  }}
                >
                  <Card.Body style={ methods.watch("selectedFlashcards").includes(flashcard.id!) ? {borderStyle: 'solid', borderWidth: 2, borderColor: "green"} : {} }>
                    <div
                      className={clsx(
                        classes.flashcard,
                        'd-flex justify-content-center',
                      )}
                    >
                      <Typography variant="span">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: flashcard.frontside_text ?? '',
                          }}
                        />
                      </Typography>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            {addMode && !searchQuery && (
              <Col className={clsx(classes.flashcardItem, 'col-2')}>
                <Card
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  className={clsx(
                    'bg-light',
                    'border-white',
                    'shadow-none',
                  )}
                  onClick={() => onAddFlashcard(index)}
                  data-testid={`add-new-flashcard-${index+1}-button`}
                >
                  <Card.Body>
                    <div
                      className={clsx(
                        classes.flashcard,
                        'd-flex align-items-center justify-content-center',
                      )}
                    >
                      <div>
                        <Typography>
                          {t('flashcards.addFlashcard')}
                        </Typography>
                        <Typography variant="h3">+</Typography>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        )
      }
      <Modal show={modalOpen} onHide={() => setModalOpen(false)} style={{marginTop: '13%'}}>
        <Modal.Header closeButton>
          <Modal.Title>{deckToMove ? 'Mover Baralho' : 'Mover Flashcards'}</Modal.Title>
        </Modal.Header>
        <form onSubmit={methods.handleSubmit(onMoveFlashcards)}>
        <Modal.Body>

          <div>

              {deckToMove ? (
                <ul>
                  <li>Seleciona a Coleção para a qual queres mover este Baralho de Flashcards.</li>
                </ul>
              ): (
              <ul>
                <li>⁠Seleciona a Coleção e respetivo Baralho para o qual queres mover o(s) Flashcard(s) selecionado(s).</li>
              </ul> )}

            <Form.Group>
              <Form.Label>Coleção</Form.Label>
              {methods.formState.errors.collection && <p style={{color: 'red'}}>Selecione uma coleção</p>}
              <Form.Select
                defaultValue=""
                {...methods.register('collection', {required: 'Selecione uma coleção'})}
              >
                <option value="">Seleciona uma coleção</option>
                {CollectionsData?.map((collection) => (
                   <option value={collection.id} key={collection.id}>{collection.title}</option>))}
              </Form.Select>
            </Form.Group>
            <Form.Group style={{marginTop: 20}}>

            {!deckToMove && (
              <>
              <Form.Label>Baralho</Form.Label>
                {methods.formState.errors.deck && <p style={{color: 'red'}}>Selecione um baralho</p>}
              <Form.Select
                defaultValue=""
                {...methods.register('deck')}
                required
              >
                <option value="">Seleciona um baralho</option>
                <option value="new">Novo Baralho</option>
                {filteredDeck.map((deck) => (
                  <option value={deck.id} key={deck.id}>{deck.name}</option>
                ))}
              </Form.Select>
              </>
            )}
            </Form.Group>


          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" size="sm" variant="dark">{deckToMove ? 'Mover Baralho' : 'Mover Flashcards'}</Button>
        </Modal.Footer>
        </form>
      </Modal>
      <Modal show={deckModal} onHide={() => setDeckModal(false)} style={{marginTop: '13%'}}>
        <Modal.Header>
          Novo Baralho
        </Modal.Header>
        <form onSubmit={deckForm.handleSubmit(onSubmitDeck)}>
        <Modal.Body style={{height: 270}}>

          <Form.Group>
            <Form.Label>Nome do Baralho</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome do Baralho"
              {...deckForm.register('name', {required: 'Nome do baralho é obrigatório'})}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Cor do Baralho</Form.Label>
            <Form.Control type="color"
              {...deckForm.register('color', {required: 'Cor do baralho é obrigatório'})}
            />
          </Form.Group>

        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" size="sm" variant="dark">Criar Baralho</Button>
        </Modal.Footer>
        </form>
      </Modal>
      <UpgradePopupModal />
    </>
  );
}

export default FlashcardsDeckDisplay;
